<template>
    <div class="inner-container">
        <div class="section-title budget"><i class="icon icon-calculator-coins"></i><span>Бюджет</span></div>
        <div class="section-subtitle"><i class="icon icon-grid"></i><span>{{ $t("app.links.budget_requests") }}<budget-forms-list :curFormSelect="'/form01-123'"/></span>
            <forms-download-reprt
                :progress="progress"
                :isReportUploading="isReportUploading"
                :form="form"
                :guListLen="guListLen"
                @checkSignatories="checkSignatories"
                @downloadRep="downloadRep"
                @downloadBatchReports="downloadBatchReports"
            ></forms-download-reprt>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <budget-header v-show="categoryMode" ref="budgetHeader" :form="form" @chgData="changeHeader" :yearReq="true" :openDisabled="openDisabled" @setProgress="setProgress"></budget-header>
                <p v-if="!categoryMode" class="title">Расшифровка. {{ caterogyName }}</p>
            </div>
            <div class="right-content">
                <div class="filter-actions filter-actions-flex">
                    <template v-if="categoryMode">
                        <c-budget-forms-copy-data
                            v-if="!(progress < 100) && variantAttribute && (header.year < header.cur_year + 2)"
                            :budgetForm="budgetForm"
                            :header="header"
                            :isLoad="isLoad"
                            @keyPress="keyPress"
                        />
                        <b-button variant="primary" @click="addItem" v-if="variantAttribute" :disabled="isLoad"><i class="icon icon-plus-circle"></i> Добавить</b-button>
                    </template>
                    <template v-else>
                        <b-button variant="light" @click="resetModal">
                           <i class="icon icon-keyboard icon-rotate-180"></i> Назад к списку
                        </b-button>
                        <b-button v-if="variantAttribute" variant="primary" @click="$refs.formTemplate.addItem()">
                            <i class="icon icon-plus-circle"></i>Добавить
                        </b-button>
                        <b-button
                            :disabled="!dataChanged || goodsAreSaving"
                            variant="success"
                            @click="prepareForSave">
                            Сохранить
                        </b-button>
                    </template>
                </div>
            </div>
        </div>

        <breadcrumbs-filter v-show="categoryMode" :header="{...header, formName: this.formName, spfName: this.spfName}" :data-type-filter="dataTypeFilter"
                            :variant-name="variantName" @openFilterByRef="openFilterByRef"/>
        <b-progress
                variant="success"
                v-show="progress < 100 && progress > 0"
                height="10px"
                :value="progress"
                striped
                animated
        ></b-progress>

        <div v-show="categoryMode" class="table-container">
            <b-table
                :fields="tableFields"
                :items="budgetForm"
                responsive="true"
                bordered
                head-variant="light"
                sticky-header="true"
                no-border-collapse
            >
                <template #top-row="data">
                    <td></td>
                    <td class="td-numbering"></td>
                    <td class="td-numbering text-center table-success">1</td>
                    <td class="td-numbering text-center table-danger">2</td>
                    <td class="td-numbering text-center table-info">3</td>
                    <td class="td-numbering text-center table-primary">4</td>
                    <td class="td-numbering text-center table-warning">5</td>
                    <td class="td-numbering text-center table-success">6</td>
                    <td class="td-numbering"></td>
                </template>
                <template #head(selection)>
                    <div class="text-center">
                        <b-form-checkbox
                            v-model="selectAll"
                            @change="e => setIsAllDelete(e)"
                            :value="true"
                            :unchecked-value="false" />
                    </div>
                </template>
                <template #head(more)>
                    <div class="text-center">
                        <i
                            title="Удалить выбранные записи"
                            class="icon icon-close table-all-remove"
                            @click="deleteItem(`${selectAll ? 'всех' : 'выбранных'} категорий`)"/>
                    </div>
                </template>
                <template #cell(selection)="data">
                    <b-form-checkbox
                        v-model="data.item.itemToDelete"
                        @input="e => { if (!e) selectAll = false; }"
                        :value="true"
                        :unchecked-value="false" />
                </template>
                <template #cell(action)="data">
                    <button
                        class="table-plus"
                        v-b-modal.goods-modal @click="setCurrCatName(data.item)"
                        :disabled="data.item.checkForDuplicates || !data.item.group || data.item.nameUpdating">
                        <i class="icon icon-plus-circle"/>
                    </button>
                </template>
                <template #cell(group)="data">
                    <div
                        class="column-width-100"
                        :class="{ 'error': data.item.checkForDuplicates }">
                        <multiselect
                                v-if="variantAttribute"
                                v-model="data.item.group_obj"
                                label="text"
                                placeholder="Выбрать категорию"
                                :options="dict"
                                :searchable="true"
                                :allow-empty="false"
                                :show-labels="false"
                                @input="() => { onChangeName(data.item); data.item.rowToSave = true; saveCats(data.item); }"
                            >
                        </multiselect>
                        <div v-else>{{ data.item.group_obj.value.name_ru }}</div>
                        <template v-if="data.item.checkForDuplicates">
                            <i class="icon icon-danger"></i>
                            <div class="pop-up">
                                <p class="red-text">Внимание! Данные не соответствуют контролю: </p>
                                <p>Такая запись уже в базе существует</p>
                            </div>
                        </template>
                    </div>
                </template>
                <template #cell(amount)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(payment)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(months)="data">
                    <b-form-input v-if="variantAttribute"
                                  class="text-right"
                                  :disabled="!data.item.group_obj"
                                  v-model="data.item.months"
                                  @update="data.item.rowToSave = true"
                                  @change="onChangeName(data.item)"
                                  @keyup="checkNumber($event, 1, 12)"
                                  @keyup.enter.exact="keyup13"
                                  @keypress="keyPress($event, '^[0-9]+$')"
                                  @blur="inputFixed(data.item, 'total', data.item.months, 3)">
                    </b-form-input>
                    <div v-else class="text-right">{{ data.value }}</div>
                </template>
                <template #cell(total)="data">
                    <div class="text-right">{{ $n(data.value) }}</div>
                </template>
                <template #cell(files)="data">
                    <span
                        class="blue pointer underline"
                        @click="onAllFilesClick({ ...data.item, name_ru: data.item.group_obj.text})"
                    >({{data.item.files ? data.item.files.length : 0}})</span>
                </template>
                <template #cell(more)="data">
                    <i class="icon icon-clear table-remove"  v-if="variantAttribute" :disabled="deletingCat" @click="deleteItem('данной категории', {...data.item, good_type: data.item}, data.index, true)"></i>
                </template>

                <template #bottom-row="data">
                    <td class="text-right" colspan="6">ИТОГО</td>
                    <td class="text-right">{{ $n(total) }}</td>
                    <td colspan="2"></td>
                </template>
            </b-table>
            <div class="table-add" v-if="variantAttribute" :disabled="isLoad">
                <span @click="addItem"><i class="icon icon-plus-circle"></i> Добавить</span>
            </div>
        </div>
        <template v-if="!categoryMode">
            <b-overlay :show="templateTableOverlay" rounded="sm">
                <form-03149-template1
                    ref="formTemplate"
                        :formCode="form.code"
                        :goodsData="goodsData"
                        :initialGoodsData="initialGoodsData"
                        :ensTruDict="ensTruDict"
                        :variantAttribute="variantAttribute"
                        :currCategory="currCategory"
                        :lang="lang"
                        :ifAllFieldsFilled="ifAllFieldsFilled"
                        :deleteGood="deleteGood"
                        :getUnitName="getUnitName"
                        :ensTruNamePrt="ensTruNamePrt"
                        :ensTruName="ensTruName"
                        :currUnits="currUnits"
                        :descIsLoading="descIsLoading"
                        :parNameLoading="parNameLoading"
                        :allFiles="allFiles"
                        @setTotals="setTotals"
                        @updateDatas="updateDatas"
                        @save="save"
                        @onDataChanged="onDataChanged"
                        @searchName="searchName"
                        @setIsAllDelete="setIsAllDelete"
                        @deleteSeveralGoods="deleteSeveralGoods"
                        :triggDownloadFile="triggDownloadFile"
                        :showFileUploadModal="showFileUploadModal"
                        :getEnstruByName="getEnstruByName"
                        :loadCurUnits="loadCurUnits"
                        :goodsAreSaving="goodsAreSaving"
                ></form-03149-template1>
                <div class="table-add" v-if="variantAttribute" >
                    <span @click="$refs.formTemplate.addItem()"><i class="icon icon-plus-circle"></i> Добавить</span>
                </div>
            </b-overlay>
        </template>
        <files-updown ref="filesUpdown"
            v-show="categoryMode"
            :header="header"
            :variant-attribute="variantAttribute"
            :load="load"
            :showDeleteLinks="variantAttribute"
            :showDeleteAll="true"
            @getFiles="getFiles"
            @fillFilesList="fillFilesList"
            @restetFilesList="restetFilesList"
            @delelteAttachedFile="delelteAttachedFile"
        ></files-updown>
        <modal-all-files-management
            ref="modalAllFilesManagement"
            :allFiles="allFiles"
            @triggDownloadFile="triggDownloadFile"
            @triggDownloadAllFiles="triggDownloadAllFiles"
            >
        </modal-all-files-management>
    </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BudgetHeader from '@/modules/budget-request/budget-header-new.vue';
import FilesUpdown from '@/modules/budget-request/FilesUpdown';
import BudgetFormsList from '@/modules/budget-request/components/budget-forms-list.vue';
import BreadcrumbsFilter from '@/modules/budget-request/components/breadcrumbs-filter.vue';
// import BudgetAttachFile from "./components/budget-attach-file";
import FormsHandlerMixin from "./mixins/forms-handler-mixin";
import Form03149Template1 from "./components/budget-forms-template1.vue";
import ModalAllFilesManagement from './components/modal-all-files-management.vue';
import CBudgetFormsCopyData from './components/budget-forms-copy-data.vue';
// import I18n from '../budget/staffing-table/I18n';
import FormsDownloadReprt from "./components/forms-download-reprt.vue";

export default {
    name: 'Form01_153',
    components: { BudgetHeader, FilesUpdown, BudgetFormsList, FormsDownloadReprt, BreadcrumbsFilter, Form03149Template1, ModalAllFilesManagement, CBudgetFormsCopyData },
    mixins: [FormsHandlerMixin],
    data() {
        return {
            form: {
                code: '01-153',
                name_kk: 'Көлiк қызметтерiне ақы төлеу бойынша шығыстарды есептеу',
                name_ru: 'Расчет расходов по оплате транспортных услуг'
            },
            tableFields: [
                {
                    key: 'selection',
                    label: ' '
                },
                {
                    key: 'action',
                    label: ' '
                },
                {
                    key: 'group',
                    label: 'Виды транспорта'
                },
                {
                    key: 'amount',
                    label: 'Кол-во (ед.)'
                },
                {
                    key: 'payment',
                    label: 'Затраты на оплату транспортных услуг в месяц (тенге)'
                },
                {
                    key: 'months',
                    label: 'Число месяцев'
                },
                {
                    key: 'total',
                    label: 'Всего расходов за год в тыс.тенге (гр.2 х гр.3 х гр.4)/1000'
                },
                {
                    key: 'files',
                    label: 'Файлы'
                },
                {
                    key: 'more',
                    label: ''
                }
            ],
            dataTypeFilter: null,
            variantAttribute: null,
            variantName: null,
            budgetForm: [],
            header: null,
            dict: [],
            files: null,
            load: false,
            openDisabled: false,
            isLoad: false
        };
    },
    mounted() {
        this.progress = 15;
    },
    methods: {
        itemUpdate(item) {
            this.$set(item, 'amount', 0);
            this.$set(item, 'payment', 0);
            this.$set(item, 'total', 0);
            this.$set(item, 'months', 1);
            this.$set(item, 'newCat', true);
            this.$set(item, 'nameUpdating', false);
            this.$set(item, 'category_id', this.uuidv4());
            this.$set(item, 'code', item.category_id);
            this.$set(item, 'checkForDuplicates', false);
            this.$set(item, 'itemToDelete', false);
        },

        changeGroup(item) {
            this.$set(item, 'group', item.group_obj.value.code);
        },

        onChangeName(value) {
            if (!value.group_obj) return;
            this.changeGroup(value);
            this.budgetForm.some(item => {
                if (item.id !== value.id) {
                    if (item.group === value.group && parseInt(item.months) === parseInt(value.months)) {
                        value.checkForDuplicates = true;
                        item.checkForDuplicates = true;
                        // если схожий по наименованию запись найдено то выходит из функции
                        return true;
                    } else {
                        value.checkForDuplicates = false;
                        item.checkForDuplicates = false;
                    }
                }
            });
        },

        fillDataForSave(item, row) {
            this.$set(item, 'category_id', this.currCategory.category_id);
            this.$set(item, 'amount', parseInt(row.amount));
            this.$set(item, 'payment', parseFloat(row.price));
        }, // заполнение полей при подготовке к сохранению товаров

        async delete(item, showMsg = true) {
            this.$set(item, 'form', this.form.code);
            try {
                const response = await fetch('/api-py/delete-budget-request-form/', {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8'
                    },
                    body: JSON.stringify(item)
                });
                const result = await response.json();
                if ((response.status === 200) && (result.result === 'success')) {
                    if (showMsg) {
                        await this.updateDatas(true);
                        this.makeToast('success', 'Сообщение', 'Запись удалена');
                    }
                }
            } catch {
                this.makeToast('danger', 'Предупреждение', 'Ошибка удаления данных');
            }
        }, // удаление данных

        setTotals(val) {
            const total = val.amount * val.price;
            this.$set(val, 'total', total);
        },

        inputFixed(item, field, value, digit) {
            if (!value) value = 1;
            const total = parseInt(item.amount) * parseFloat(item.payment) * parseInt(item.months) / 1000;
            this.$set(item, field, parseFloat((Math.round(total * 1000) / 1000).toFixed(digit)));
            if (!item.group) return;
            this.saveCats(item);
        }, // форматирует введенное значение до digit цифр после запятой

        getFiles(data) {
            this.files = data;
        },

        getItem(code, list) {
            for (const item of list) {
                if (parseInt(item.value.code) === parseInt(code)) {
                    return item;
                }
            }
            return null;
        }, // возвращает объект по коду с заданного списка

        getRowKey(row, keys) {
            let key = '';
            for (const k of keys) {
                key = key + this.padLeadingZeros(row[k], 3) + '.';
            }
            return key;
        }, // преобразует значения выбранных полей в код

        keyPress: function (event, pattern) {
            const regex = new RegExp(pattern);
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит по заданному паттерну

        keyup13: function (event) {
            event.preventDefault();
            // Isolate the node that we're after
            const currentNode = event.target;
            // find all tab-able elements
            const allElements = document.querySelectorAll('input'); // area, object, select, [contenteditable]
            // Find the current tab index.
            const currentIndex = [...allElements].findIndex(el => currentNode.isEqualNode(el));
            // select/focus the following element
            const targetIndex = (currentIndex + 1) % allElements.length;
            if (targetIndex < allElements.length) {
                allElements[targetIndex].select();
            }
        }, // enter работает как tab

        getFormCode() {
            // при необходимости заменить в родительском компоненте. Напр. Form01_142.vue
            return `${this.form.code}-decode`;
        },

        async loadCategoryData() {
            this.load = true;
            await this.loadDatas();
            if (!this.budgetForm.length) return;
            const catIdList = [];
            this.budgetForm.forEach(item => catIdList.push(item.category_id))
            this.$set(this.header, 'cat_id_list', catIdList)
            try {
                const response = await fetch('/api-py/get-budget-request-form-cat/' + JSON.stringify(this.header));
                    if (response.status === 200) {
                        const values = await response.json();
                        this.budgetForm.forEach(item => {
                            const currVal = values.find(val => val.category_id === item.category_id);
                            if (currVal) {
                                if (this.variantAttribute && (item.amount !== currVal.sum_amount || item.payment !== parseFloat(currVal.avg_price.toFixed(2)))) {
                                    this.$set(item, 'amount', Math.round(currVal.sum_amount));
                                    this.$set(item, 'payment', parseFloat(currVal.avg_price.toFixed(2)));
                                    this.$set(item, 'rowToSave', true);
                                    if (this.variantAttribute) this.saveCats(item, false);
                                }
                                const total = parseInt(item.amount) * parseFloat(item.payment) * parseInt(item.months) / 1000;
                                this.$set(item, 'total', parseFloat(total.toFixed(3)));
                                this.$set(item, 'files', this.getUnicFilesArr(currVal));
                            } else {
                                if (this.variantAttribute && (item.amount !== 0 || item.payment !== 0)) {
                                    this.$set(item, 'amount', 0);
                                    this.$set(item, 'payment', 0);
                                    this.$set(item, 'rowToSave', true);
                                    if (this.variantAttribute) this.saveCats(item, false);
                                }
                                this.$set(item, 'total', 0);
                                this.$set(item, 'files', []);

                            }

                        }); //привеодим данные в таблице категорий в соответствие с расшифровками
                    }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadCategoryData()', error.toString());
            }
            this.load = false;
        },

        async loadDatas() {
            this.load = true;
            let values = [];
            try {
                const response = await fetch('/api-py/get-budget-request-form/' + JSON.stringify(this.header));
                values = await response.json();

                // if (values.length === 0) {
                //     return;
                // }
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDatas()', error.toString());
            }

            const newBudgetDatas = [];
            await values.forEach(val => {
                const item = {
                    id: val.id,
                    group: val.group,
                    group_obj: this.getItem(val.group, this.dict),
                    code: val.category_id,
                    category_id: val.category_id,
                    amount: val.amount,
                    payment: val.payment,
                    months: val.months,
                    total: 0,
                    rowToSave: false,
                    newCat: false,
                    oldName: val.group,
                    nameUpdating: false,
                    itemToDelete: false,
                    checkForDuplicates: false
                };
                newBudgetDatas.push(item);
            });
            this.budgetForm = newBudgetDatas;
            this.budgetForm.sort((a, b) => a.id - b.id);
            this.load = false;
        },

        prepareData(val) {
            this.$set(val, 'price', val.payment)
        },

        async loadDict() {
            this.dict.splice(0);
            try {
                const response = await fetch('/api-py/dictionary/transport_groups/');
                const items = await response.json();

                for (const row of items) {
                    const el = {};
                    this.$set(el, 'value', row);
                    this.$set(el, 'text', row.name_ru);
                    this.dict.push(el);
                }
                this.dict.sort((a, b) => a.text.toLowerCase() > b.text.toLowerCase() ? 1 : -1);
            } catch (error) {
                this.makeToast('danger', 'Ошибка запроса loadDict()', error.toString());
            }
        },

        noAbc: function (event) {
            const regex = new RegExp('^-?\\d*\\.?\\d{0,9}$');
            const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
            if (!regex.test(key)) {
                event.preventDefault();
                return false;
            }
        }, // вводит только цифры

        padLeadingZeros(num, size) {
            let s = String(num);
            while (s.length < size) { s = '0' + s; }
            return s;
        }, // добавляет 0-ли перед num до size-значного размера

        rowClass(item, type) {
            if (!item || type !== 'row') {
                return;
            }
            if (!item.visible) {
                return 'is-hidden';
            }
        }, // задает класс 'is-hidden' заданной строке

        getCodeFieldName() {
            return 'category_id';
        },

        updateTotalBeforeSave(isCatDeleting = false, prop = 'totalAll') {
            const currTotal = isCatDeleting ? {totalAmount: 0, totalAvgPrice: 0} : this.$refs.formTemplate.getTotal01153()[prop];
            if (currTotal.totalAmount >= 0 && currTotal.totalAvgPrice >= 0) {
                for (const cat of this.budgetForm) {
                    if (cat[this.getCodeFieldName()] === this.currCategory.code) {
                        let total = 0;
                        if (isCatDeleting) {
                            this.$set(cat, 'amount', currTotal.totalAmount);
                            this.$set(cat, 'payment', currTotal.totalAvgPrice);
                            total = parseInt(cat.amount) * parseFloat(cat.payment) * parseInt(cat.months) / 1000;
                        } else total = this.newTotal(cat);
                        this.$set(cat, 'total', parseFloat((Math.round(total * 1000) / 1000).toFixed(3)));
                    }
                }
            }
            this.updtHeader();
        },

        // пересчет тотала при удалении нескольких записей
        calcGoodsTotalBeforeDel() {
            return this.budgetForm.length
                ? this.budgetForm.reduce((acc, curr) => {
                                if (this.currCategory && curr.category_id === this.currCategory.code) {
                                    const newTotalRounded = this.newTotal(curr);
                                    return acc + newTotalRounded

                                } else return acc + curr.total;
                                }, 0)
                : 0;
        },

        newTotal(curr) {
            const goodsTotals = this.$refs.formTemplate.getTotal()['totalAll'];
            const goodsAmount = this.$refs.formTemplate.getTotal01153()['totalAll']['totalAmount'];
            let avgSalary = 0;
            if (goodsAmount > 0) {
                avgSalary = parseFloat((Math.round(goodsTotals / goodsAmount * 100) / 100).toFixed(2));
            }
            const newTotal = goodsAmount * avgSalary * curr.months / 1000
            const newTotalRounded = parseFloat((Math.round(newTotal * 1000) / 1000).toFixed(3));
            return newTotalRounded;
        }

    },
    computed: {
        total() {
            let sum = 0;
            for (const row of this.budgetForm) {
                sum += parseFloat(row.total);
            }
            return Math.ceil(sum);
        },
        caterogyName() {
            const localNameField = this.$i18n.locale === 'kk' ? 'name_kk' : 'name_ru';
            const localeMonths = this.$i18n.locale === 'kk' ? 'ай' : 'мес.';
            return `${this.currCategory.group_obj.value[localNameField]} (${this.currCategory.months} ${localeMonths})`
        }
    }
};
</script>
<style scoped>
.error .multiselect__tags {
    padding-left: 25px;
}
.filter-actions-flex {
    display: flex;
}
.table-plus {
    border: none;
}
</style>